<template>
  <div class="w-full">
    <form @submit.prevent="submit">
      <div class="px-4 pt-4 md:px-6 md:pt-6">
        <!-- Modal Header -->
        <div
          class="flex flex-col items-center justify-start pb-4 border-b border-gray-200 dark:border-neutral-600 md:pb-6 sm:flex-row"
        >
          <div
            class="flex items-center justify-center flex-shrink-0 w-12 h-12 bg-blue-100 rounded-full dark:bg-blue-600 ring-2 ring-transparent dark:ring-blue-400"
          >
            <fa-icon
              icon="pen-to-square"
              class="text-blue-500 dark:text-blue-100 fa-fw"
            />
          </div>
          <div class="w-full mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3
              class="text-lg font-medium leading-6 text-gray-900 truncate dark:text-neutral-100"
              id="modal-title"
            >
              Rename Previz
            </h3>
          </div>
        </div>
        <!-- Modal Content -->
        <div
          class="my-4 space-y-4 text-center md:space-y-6 md:my-6 sm:text-left"
        >
          <div class="relative mt-1 rounded-md shadow-sm">
            <p
              class="mt-2 text-sm text-red-600"
              id="title-error"
              v-show="error"
            >
              {{ error }}
            </p>
            <input
              type="text"
              class="block w-full border-gray-200 rounded-md shadow-sm dark:placeholder-neutral-400 dark:focus:placeholder-neutral-100 dark:text-neutral-400 dark:focus:text-neutral-100 dark:bg-black dark:focus:bg-neutral-900 focus:ring-indigo-500 focus:border-indigo-500 dark:shadow-inner dark:border-neutral-600 focus-within:ring-1 dark:focus-within:border-transparent dark:focus-within:ring-2 dark:focus-within:ring-indigo-600"
              name="name"
              v-model="form.name"
              autofocus
              ref="input"
            />
          </div>
        </div>
      </div>
    </form>
    <!-- Modal Footer -->
    <div
      class="flex flex-row p-4 space-x-4 bg-gray-100 border-t border-transparent dark:bg-neutral-800 dark:border-neutral-600 md:p-6 md:space-x-reverse md:flex-row-reverse md:justify-start"
    >
      <template v-if="form.busy">
        <button
          class="inline-flex justify-center flex-1 px-4 py-2 text-sm font-medium text-white bg-gray-500 border border-transparent rounded-md shadow-sm cursor-wait shrink-0 md:flex-auto md:flex-grow-0 dark:text-neutral-100 dark:ring-offset-0 dark:bg-neutral-600 dark:border-neutral-400"
          type="button"
        >
          <fa-icon
            icon="spinner"
            spin
            class="my-auto mr-1 dark:text-neutral-100"
          />Working
        </button>
      </template>
      <template v-else>
        <button
          type="button"
          :class="{ loading: form.busy }"
          @click.prevent="checkForm"
          class="inline-flex justify-center flex-1 px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm shrink-0 md:flex-auto md:flex-grow-0 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:text-neutral-100 dark:ring-offset-0 dark:bg-blue-700 dark:hover:bg-blue-600 dark:focus:ring-1 dark:focus:border-blue-600 dark:focus:ring-blue-400"
        >
          Rename Previz
        </button>
        <button
          type="button"
          @click="$emit('close')"
          class="inline-flex justify-center flex-1 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm dark:bg-transparent dark:text-neutral-400 dark:hover:text-neutral-100 dark:hover:border-neutral-400 dark:border dark:border-neutral-600 dark:rounded-md dark:hover:bg-neutral-600 shrink-0 md:flex-auto md:flex-grow-0 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Cancel
        </button>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'asset-rename',
  props: ['asset', 'project'],
  data() {
    return {
      form: new SparkForm({
        name: null
      }),
      formFormHasErrors: false,
      error: null
    }
  },
  watch: {
    asset: {
      handler(asset) {
        this.form.name = asset.name
      },
      immediate: true
    }
  },
  mounted() {
    this.$refs.input.focus()
  },
  methods: {
    submit() {
      if (this.readyToSubmit) {
        this.form.startProcessing()
        this.$store
          .dispatch('assets/rename', {
            project: this.project,
            asset: this.asset,
            name: this.form.name
          })
          .then((response) => {
            if (response.errors) {
              this.error = response.errors[0].detail
              this.form.finishProcessing()
            } else {
              this.form.finishProcessing()
              this.$emit('close')
            }
          })
          .catch(() => {
            this.form.finishProcessing()
          })
      }
    },
    checkForm() {
      this.error = null
      if (this.$refs.input.value.trim().length === 0) {
        this.error = 'Name cannot be blank'
      }
      this.submit()
    }
  },
  computed: {
    readyToSubmit() {
      return !this.error
    }
  }
}
</script>
